import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Sidebar from "../components/Repeating/SidebarCta";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Chula Vista Conservatorship Lawyer",
      content: (
        <>
          <p>
            A conservatorship gives you the legal framework to manage the
            affairs of a loved one who is unable to take care of themselves
            either physically or mentally. It allows you to handle their
            financial matters and attend to their personal needs to ensure their
            comfort and security.
          </p>
          <p>
            If you’re facing this type of situation, our conservatorship lawyers
            in Carlsbad have decades of expertise and are here to help guide you
            through the process.
          </p>
          <a href="https://briertonjones.com/conservatorship-lawyer/">
            Learn More About Conservatorship
          </a>
        </>
      ),
    },
    {
      title: "Chula Vista Guardianship Lawyer",
      content: (
        <>
          <p>
            A guardianship legally allows someone to step in if a child’s
            parents are physically or mentally unable to provide care. The
            parents retain their parental rights, but a legal guardian can make
            decisions about a child’s personal care, finances, education, and
            medical treatment.
          </p>
          <p>
            We have experienced guardianship lawyers on our team who can guide
            you through the process and make it as smooth as possible.
          </p>
          <a href="https://briertonjones.com/guardianship-lawyer/">
            Learn More About Guardianship
          </a>
        </>
      ),
    },
    {
      title: "Chula Vista Special Needs Trust Lawyer",
      content: (
        <>
          <p>
            For parents who care for a child with a disability, estate planning
            is a key factor in securing their future well-being. A cornerstone
            of this planning is establishing a special needs trust, which allows
            you to earmark assets for your loved one and ensure that, as a
            disabled adult, they maintain essential government benefits, such as
            SSI and Medi-Cal.
          </p>
          <p>
            Our special needs trust lawyers in Chula Vista can guide you through
            the process, giving you the peace of mind that you've planned for
            your loved one's future.
          </p>
          <a href="https://briertonjones.com/special-needs-trust-lawyer/">
            Learn More About Special Needs Trust
          </a>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust Administration Lawyers Serving Chula Vista California"
        description="Trust Administration Lawyers in Chula Vista, California: Dedicated to guiding clients through the intricate trust administration process with professionalism and care"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <article>
        <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
          <div className="relative">
            <div className="container">
              <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
                <div className="md:pr-6 lg:pr-12">
                  <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                    SECURE YOUR LEGACY
                  </div>
                  <h1>Chula Vista Trust Administration Lawyer</h1>
                  <p>
                    Whether you need assistance with asset distribution or help
                    to settle debts and taxes, Brierton, Jones & Jones, LLP is
                    here to help. With over 30 years of experience in trust
                    administration, our lawyers remain dedicated to serving the
                    community of Chula Vista with trust, compassion, and
                    respect.
                  </p>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Schedule a Consultation"
                  />
                </div>

                <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[45vw] md:pl-6 lg:pl-12">
                  <StaticImage
                    src="../images/city pages/Chula Vista.png"
                    loading="eager"
                    className="h-full"
                    imgClassName="object-left"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-20 pt-14 md:mb-32 md:pt-20">
          <div className="container">
            <div className="flex justify-between md:space-x-14 lg:space-x-28">
              <div>
                <div className="mb-12 md:mb-18">
                  <h2>Why You Need a Trust Administration Lawyer</h2>
                  <p>
                    30 years of experience have shown us that trust cases can be
                    legally and emotionally overwhelming—especially when placed
                    in the wrong hands.
                  </p>
                  <p>
                    That’s why Chula Vista has relied on our legal team for over
                    30 years.
                  </p>
                  <p>
                    We simplify intricate trust administration cases with a
                    personalized approach, upholding your wishes and protecting
                    your loved ones' interests. With us, your trust
                    administration case is in reliable and caring hands—a
                    commitment we've upheld for over three decades.
                  </p>
                </div>

                <div>
                  <h3>What’s Involved in Trust Administration</h3>
                  <p>
                    Successful trust administration demands experience, empathy,
                    and expertise. That's precisely how we approach every step
                    of your case, from asset inventory and distribution to
                    accounting, tax payment, and resolution.
                  </p>
                </div>
                <div>
                  <h3>We Provide Assurance and Peace of Mind</h3>
                  <p>
                    Legal mistakes can be frustrating and costly. Our approach
                    ensures swift case resolution, free from such errors,
                    providing the peace of mind you and your family deserve.
                  </p>
                </div>
                <div className="pt-[120px]">
                  <h3>Related Practice Areas</h3>
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                  >
                    {accordionContent.map((item, i) => (
                      <AccordionItem
                        uuid={i}
                        className="border-b border-gray-100 pb-5"
                      >
                        <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                          <AccordionItemState>
                            {(state) => {
                              return (
                                <>
                                  <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                    {item.title}
                                  </h3>
                                  <i
                                    className={`far ml-2 text-xl text-seafoam ${
                                      state.expanded
                                        ? "fa-minus"
                                        : "fa-chevron-down"
                                    }`}
                                  ></i>
                                </>
                              );
                            }}
                          </AccordionItemState>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-5">
                          {item.content}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <ButtonSolid
                    modal="modal-contact"
                    text="Contact us"
                    className="mt-8"
                  />
                </div>
              </div>

              <div className="hidden md:block">
                <div className="sticky top-24">
                  <aside>
                    <div className="ml-auto mt-14 w-[298px] rounded-3xl border border-seafoam px-4 py-6">
                      <div className="mb-4 font-display text-2xl font-semibold text-typography-heading">
                        Schedule a Consultation
                      </div>
                      <p className="font-display text-lg">
                        When you need a Chula Vista trust administration
                        attorney to help you put everything in order, Brierton,
                        Jones & Jones, LLP is here for you.
                      </p>

                      <div className="space-y-4">
                        <ButtonGhost
                          href="tel:619-485-5394"
                          text="(619) 485-5394"
                          className="w-full"
                        />
                        <ButtonSolid
                          modal="modal-contact"
                          text="Send a Message"
                          className="w-full"
                        />
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mb-20">
            <StaticImage
              src="../images/city pages/Chula Vista Map.jpg"
              loading="eager"
              className="w-full"
            />
          </div>
        </section>
      </article>
      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="When you need a San Diego estate planning attorney to help you put everything in order, Brierton, Jones & Jones, LLP is here for you." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/01 Estate Planning.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
